import React, {useEffect, useRef, useState} from 'react';
import {Alert, Button, Col, Collapse, CollapseProps, Form, Input, Layout, Row, Spin, Switch} from 'antd';
import {useLocation, useNavigate} from "react-router-dom"
import {fetchBackendToken} from "./utils/backend"
import Markdown from 'react-markdown'
import {saveAs} from 'file-saver';
import {ReactComponent as Logo} from './icons/base_logo_light.svg'
import {colorPrimary, colorSecondary, colorTextSecondary} from "./global"
import {CaretRightOutlined} from '@ant-design/icons';
import LogOut from "./components/LogOut"
import PlotRnaLinear from './icons/rna_linear.png'
import PlotRnaCircular from './icons/rna_circular.png'
import {useSessionState} from "./utils/session_state"
import SelectScenario from "./components/SelectScenario"
import FormMap from "./components/FormMap"
import {initScenario} from "./utils/session_state_init"


const {Header, Content} = Layout

export const useNavigateHome = (page_link = '') => {
    const location = useLocation()
    let page_title = location.pathname.substring(1)
    const navigate = useNavigate()
    console.log({page_title})

    useEffect(() => {
        if (page_title !== page_link) {
            console.log('redirecting')
            navigate(page_link)
        }
    }, [page_title])
}


const initDefaultValues = {
    design_options: [],
    parameters: [],
    output_options: []

}


const OutputText: React.FC<any> = (
    {output, verbose}
) => {
    if (output === undefined)
        return null

    return (
        <div
            style={{
                width: '80%',
                margin: '10px',
            }}>

            <div style={{
                fontFamily: 'courier',
                fontSize: '16px',
                borderRadius: 4,
                border: '0.1px solid lightgrey',
                padding: '10px',
                wordWrap: "break-word",
                backgroundColor: 'white'

            }}>
                {verbose &&

                    <Markdown>
                        {output.verbose}
                    </Markdown>

                }
                <Markdown>
                    {output.text}
                </Markdown>
            </div>
        </div>
    )
}
const Output: React.FC<any> = (
    {formError, job_loading, scenario, verbose}
) => {
    const {output, error} = scenario
    const style = {
        marginBottom: '5vh',
        padding: '20px'
    }
    if (formError) {
        return (

            <Alert
                showIcon
                style={style}
                type="error"
                message="Configuration contains errors"


            />)
    } else if (job_loading === 'error') {
        return (
            <>
                <Alert
                    showIcon
                    style={{
                        // marginTop: '5vh',
                        marginBottom: '5vh',
                        padding: '20px',
                    }}
                    type="error"
                    message="Your job failed"
                />

                <Markdown>
                    {error}
                </Markdown>
                <div style={style}/>

            </>

        )
    } else if (job_loading === true) {
        if (verbose) {
            return (
                <>
                    <Spin/>
                    <OutputText
                        output={output}
                        verbose={verbose}
                    />
                    <div style={style}/>

                </>)
        } else {
            return (
                <Alert
                    showIcon
                    type="success"
                    message="Scenario submitted. A mail will be sent to you with the results."
                    style={style}

                />
            )
        }

    } else if (job_loading === false) {
        return (
            <>
                <OutputText
                    output={output}
                    verbose={verbose}
                />
                <div style={style}/>

            </>
        )

    }

    return null

}

const App: React.FC = () => {
    useNavigateHome()
    const elementRef = useRef<null | HTMLDivElement>(null)

    function scrollToElement() {
        elementRef.current?.scrollIntoView({behavior: "smooth", block: "end"})
    }

    const [form] = Form.useForm()
    const [firstTime, setFirstTime] = useState<boolean>(true)
    const [verbose, setVerbose] = useState<boolean>(false)
    const [defaultValues, setDefaultValues] = useState<any>(initDefaultValues)
    const [formError, setFormError] = useState<boolean>(false)
    const [tmpScenario, setTmpScenario] = useState<any>(initScenario)
    const {sessionState, setSessionState, setSessionStateLoading, sessionStateLoading} = useSessionState()

    // console.log({sessionState, firstTime, sessionStateLoading})

    let tmpScenarioRef = 'init'
    if (sessionState.scenario_key && firstTime === false)
        tmpScenarioRef = `/scenarios/${sessionState.scenario_key}`


    const {
        sessionState: scenario,
        sessionStateLoading: scenarioLoading,
        setSessionStateLoading: setScenarioLoading
    } = useSessionState(tmpScenarioRef, tmpScenario)

    const scenarioRef = scenario.ref


    useEffect(() => {
            if (scenarioRef) {
                form.resetFields()
                form.setFieldsValue(scenario.args)
            }

        }
        , [scenarioRef])

    let {job_loading} = scenario
    console.log({job_loading, scenario})
    if (job_loading === undefined || job_loading === null || sessionState.scenario_key === null || sessionState.scenario_key === undefined)
        job_loading = 'init'


    // console.log({defaultValues})

    function setScenarioKeyNull() {
        console.log('setScenarioKeyNull')
        setFormError(false)
        setTmpScenario(scenario)

        if (sessionState.scenario_key === null)
            return

        setSessionState((prevState: any) => ({
            ...prevState,
            scenario_key: null
        }));
        // setSessionStateLoading('init')
    }


    if (firstTime && sessionStateLoading === false) {
        console.log('firstTime')
        setFirstTime(false)
        setScenarioKeyNull()
    }


    const defaultValuesList = defaultValues.design_options.concat(defaultValues.parameters)
    const defaultValuesNameSequence = defaultValuesList.reduce(
        (obj: any, item: any) => {
            if (item.type === 'sequence')
                obj[item.name] = item.default_value
            return obj
        }, {})

    // console.log({defaultValuesNameSequence})

    async function fetchDefaultValues() {
        const data = await fetchBackendToken('/get_default_values')
        if (data)
            setDefaultValues(data)

    }


    async function postValues(values: any) {
        const data = await fetchBackendToken('/get_circ_mrna', {
            method: 'POST',
            body: JSON.stringify(values)
        })
        if (data)
            setSessionStateLoading('init')


    }

    useEffect(() => {
        fetchDefaultValues()
    }, [])

    useEffect(() => {
        let intervalId: any

        if (verbose && job_loading) {
            intervalId = setInterval(() => {
                console.log('reload')
                setScenarioLoading('init');
            }, 5000); //  5s
        }

        // Cleanup function to clear the interval
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [verbose, job_loading])


    const span_output = 4
    const collapseItems: CollapseProps['items'] = [
        {
            key: '0',
            label: <div style={{color: 'white'}}>Plot instructions</div>,
            children:
                <>
                    <Row>
                        <Col span={11}
                             offset={1}
                             style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}

                        >
                            <img
                                style={{width: '40vw'}}

                                src={PlotRnaLinear}
                            />
                        </Col>
                        <Col span={11}
                             offset={1}
                             style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                        >
                            <img
                                style={{width: '20vw'}}

                                src={PlotRnaCircular}
                            />
                        </Col>
                    </Row>

                </>
        },
        {
            key: '1',
            label: <div style={{color: 'white'}}>Design options</div>,
            children:
                <>
                    <FormMap
                        form={form}
                        defaultValues={defaultValues.design_options}/>
                    <Row
                        gutter={40}
                    >
                        <Col>
                            <Button
                                style={{width: '120%'}}

                                onClick={() => {
                                    form.setFieldsValue({...defaultValuesNameSequence})
                                }}

                            >
                                sample
                            </Button>
                        </Col>
                        <Col>
                            <div/>
                        </Col>
                    </Row>

                </>
        },
        {
            key: '2',
            label: <div style={{color: 'white'}}>Parameters</div>,

            children: <FormMap
                form={form}
                defaultValues={defaultValues.parameters}/>
        },
        {
            key: '3',
            label: <div style={{
                color: 'white'
            }}>Output options</div>,

            children:


                <Row>

                    <Col span={span_output}>
                        <Form>
                            <Form.Item label='verbose'
                                // noStyle
                            >
                                <
                                    Switch
                                    defaultValue={verbose}
                                    onChange={(value) => {
                                        setVerbose(value)
                                    }}
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={span_output}>
                        <Button

                            disabled={job_loading}
                            onClick={() => {
                                const blob = new Blob([scenario.output.csv], {type: "text/csv;charset=utf-8"});
                                saveAs(blob, "output.csv")
                            }}
                        >
                            download as csv
                        </Button>
                    </Col>


                    <Col span={span_output + 3}>
                        <>
                            <Form.Item
                                label='New scenario' name='scenario_key'
                                style={{width: '80%'}}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            console.log({value, sessionState})
                                            if (value === undefined) {
                                                return Promise.resolve()
                                            } else if (value.length < 3) {
                                                return Promise.reject(new Error('Scenario name must be at least 3 characters'))
                                            } else if (sessionState.scenarios_keys.includes(value)) {
                                                return Promise.reject(new Error('Scenario name already exists'))
                                            } else {
                                                return Promise.resolve()
                                            }
                                        }
                                    }
                                ]}
                            >
                                <Input
                                    placeholder='scenario name'
                                />

                            </Form.Item>
                        </>
                    </Col>


                    <Col span={span_output + 2}>
                        <SelectScenario
                            scenarios={sessionState}
                            setScenariosLoading={setSessionStateLoading}
                            compact
                        />
                    </Col>
                </Row>


        }
    ]


    return (
        <Layout


            style={{backgroundColor: "transparent"}}>
            <Header
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'start',
                    width: '100%',
                    backgroundColor: colorPrimary,
                }}
            >
                <Row
                    style={{width: '100%'}}

                >
                    <Col span={6}
                         style={{
                             display: 'flex',
                             alignItems: 'center',

                         }}>

                        <Logo style={{
                            height: '60%',
                            display: 'flex',
                            marginTop: '10px',
                            // flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',

                        }}/>
                    </Col>
                    <Col span={18}
                         style={{
                             display: 'flex',
                             alignItems: 'center',
                             justifyContent: 'end',
                         }}
                    >

                        <LogOut/>
                    </Col>
                </Row>
            </Header>
            <Form
                form={form}
                style={{width: '100%'}}
                onValuesChange={(values) => {
                    console.log("change")
                    console.log({formError})
                    setScenarioKeyNull()
                }}
                onFinishFailed={(values) => {
                    console.log('error')
                    setFormError(true)

                    scrollToElement()

                }}
                onFinish={
                    async (values) => {
                        console.log('submit')
                        setFormError(false)
                        console.log('formValues', values)
                        await postValues(values)
                        scrollToElement()


                    }

                }
            >
                <Content
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        backgroundColor: "transparent",
                    }}
                >


                    <Collapse
                        items={collapseItems}
                        expandIcon={({collapsible, isActive}) =>
                            <CaretRightOutlined
                                style={{
                                    color: collapsible === 'disabled' ? 'grey' : 'white',
                                }}
                                rotate={isActive ? 90 : 0}/>}
                        defaultActiveKey={['0', '1']}
                        style={{
                            width: '80%',
                            margin: '10px',
                            marginTop: '30px',
                            backgroundColor: colorSecondary,


                        }}
                    />
                    <Row
                        gutter={40}
                        style={{
                            width: '20%',
                        }}

                    >
                        <Col span={12}>
                            <                       >
                                <Form.Item
                                    style={{width: '100%'}}
                                    name="submit"
                                >
                                    <Button
                                        style={{
                                            width: '100%',
                                            borderColor: formError ? 'red' : colorTextSecondary

                                        }}
                                        loading={job_loading === true || sessionStateLoading !== false}
                                        type='primary'
                                        htmlType='submit'
                                    >
                                        Submit
                                    </Button>
                                </Form.Item>
                            </>
                        </Col>
                        <Col span={12}>
                            <Button
                                style={{
                                    width: '100%',
                                    borderColor: colorPrimary
                                }}
                                loading={sessionStateLoading !== false}
                                onClick={() => {
                                    form.resetFields()
                                    setScenarioKeyNull()
                                }}
                            >
                                Clear
                            </Button>
                        </Col>

                    </Row>

                    <Output
                        formError={formError}
                        job_loading={job_loading}
                        scenario={scenario}
                        verbose={verbose}
                    />
                    <div ref={elementRef}/>

                </Content>
            </Form>


        </Layout>

    )

};
export default App;