import {getAuth} from "firebase/auth";
import {initializeApp} from 'firebase/app';
import 'firebase/auth';

const firebase = {
    apiKey: "AIzaSyAIGFxvCIPRB6q_fbOCvDi-BHqf2Baxmw4",
    authDomain: "nocturna-kraz.firebaseapp.com",
    projectId: "nocturna-kraz",
    storageBucket: "nocturna-kraz.appspot.com",
    messagingSenderId: "640794778164",
    appId: "1:640794778164:web:5100b694681d17137cc861"
}

export const app = initializeApp(firebase);
export const auth = getAuth(app);






