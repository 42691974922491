import {Button, Col, Form, Input, Row, Switch} from 'antd'
import React from 'react'
import CustomSlider from "./CustomSlider"

interface FormSequenceProps {
    form: any
    label: string
    name: string
    help: string
    default_value: string
}

const FormSequence: React.FC<any> = (
    {form, label, name, help, default_value}
) => {
    const span = 20
    return (

        <Row>
            <Col span={span}>
                <Form.Item label={label} name={name}
                           rules={[{
                               required: true, message: 'Input required',
                           },
                               {
                                   validator: (_, value) => {
                                       const regex = /^[ACGTU]*$/;
                                       if (!value || value === '') {
                                           return Promise.resolve()
                                       } else
                                           return regex.test(value) ? Promise.resolve() : Promise.reject(new Error('MRNA sequence should only contain letters ACGTU'))
                                   },
                               }
                           ]}
                >

                    <Input
                        placeholder={help}

                    />
                </Form.Item>
            </Col>
            <Col span={24 - span}
                 style={{
                     display: 'flex',
                     // justifyContent: 'center',
                     // alignItems: 'center',
                     paddingLeft: '10px',
                     // paddingTop: '5px'
                 }}

            >
                <Button
                    style={{width: '100%'}}
                    onClick={() => {
                        form.setFieldValue(name, default_value)
                    }}

                >
                    sample
                </Button>
            </Col>
        </Row>


    )
}

interface FormIntProps {
    form: any
    label: string,
    name: string,
    default_value: number,
    min: number,
    max: number,
    step: number
}

const FormNumber: React.FC<any> = (
    {
        label,
        name,
        default_value,
        min,
        max,
        step = 1
    }
) => {

    return (
        <Form.Item label={label} name={name}
                   initialValue={default_value}


        >

            <CustomSlider
                min={min}
                max={max}
                step={step}


            />
        </Form.Item>
    )
}

// const FormFloat


interface FormBooleanProps {
    form: any,
    default_value: boolean
    name: string
    label: string
}

const FormBoolean: React.FC<any> = (
    {
        form,
        name,
        label,
        default_value = false,
        true_value,
        false_value,
    }
) => {

    return (
        <Form.Item label={label} name={name}
                   initialValue={default_value}


        >

            <Switch
                checkedChildren={true_value}
                unCheckedChildren={false_value}

            />
        </Form.Item>
    )
}

interface FormMapProps {
    form: any
    defaultValues: Array<any>
}


const FormMap: React.FC<FormMapProps> = (
    {
        form,
        defaultValues
    }
) => {

    return (

        <>

            {Array.from({length: Math.ceil(defaultValues.length / 2)}, (_, i) => i * 2).map(i => {
                return (
                    <Row key={i} gutter={40}>
                        <Col span={12}>
                            {defaultValues[i].type === 'sequence' && <FormSequence form={form} {...defaultValues[i]} />}
                            {defaultValues[i].type === 'number' && <FormNumber  {...defaultValues[i]} />}
                            {defaultValues[i].type === 'boolean' && <FormBoolean  {...defaultValues[i]} />}

                        </Col>
                        <Col span={12}>
                            {defaultValues[i + 1] && defaultValues[i + 1].type === 'sequence' &&
                                <FormSequence form={form} {...defaultValues[i + 1]} />}
                            {defaultValues[i + 1] && defaultValues[i + 1].type === 'number' &&
                                <FormNumber  {...defaultValues[i + 1]} />}
                            {defaultValues[i + 1] && defaultValues[i + 1].type === 'boolean' &&
                                <FormBoolean  {...defaultValues[i + 1]} />}
                        </Col>
                    </Row>
                );
            })}


        </>
    )
}
export default FormMap
