import {Col, InputNumber, Row, Slider, SliderSingleProps} from 'antd'
import React from 'react'
import {colorPrimary} from "../global"

interface CustomSliderProps {
    min: number
    max: number
    step?: number

    value?: number
    onChange?: (value: number | null) => void
    inputNumber?: boolean
    range?: any
    tooltip?: any

}

const CustomSlider: React.FC<CustomSliderProps> = (
    {
        min,
        max,
        step,
        value,
        onChange,
        inputNumber = false,
        range = false,
        tooltip = []
    }) => {
    let marks: SliderSingleProps['marks'] = {
        [min]: {
            style: {
                color: "grey",
            },
            label: min,
        },
        [max]: {
            style: {
                color: "grey",
            },
            label: max,
        },
    }

    if (value) {
        marks = {
            ...marks,
            [value]: {
                style: {
                    color: colorPrimary,
                    bottom: '16px'
                },
                label: value,
            },
        }
    }

    const slider = (
        <Slider
            range={range}
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={onChange}
            // style={{}}

            marks={marks}
            tooltip={{
                ...tooltip,
                formatter: null
            }}
        />
    )

    return (
        <>
            {inputNumber ?
                <Row>
                    <Col span={4}>
                        <InputNumber
                            min={min}
                            max={max}
                            step={step}
                            value={value}
                            onChange={onChange}
                        />
                    </Col>
                    <Col span={16}>
                        {slider}

                    </Col>
                </Row> :
                <>
                    {slider}
                </>
            }
        </>
    )
}

export default CustomSlider
