import {Link} from "react-router-dom"
import {Button, Menu} from "antd"
import {LogoutOutlined, UserOutlined} from "@ant-design/icons"

const userItems = [
    {
        label:
            <Button
                shape='circle' icon={
                <UserOutlined
                    style={{
                        fontSize: '15px',
                        color: 'white',

                    }}
                />
            }
                style={{
                    height: '40px', width: '40px',
                    backgroundColor: 'transparent',
                    // borderColor: 'transparent'

                }}
            />,

        key: 'logout-mail',
        children: [
            {
                label:
                    <div>
                        User configuration
                    </div>,
                key: 'mail',
                disabled: true
            },
            {
                label:
                    <Link to='/login'>
                        <Button
                            style={{
                                borderColor: 'transparent'
                            }}
                            icon={<LogoutOutlined/>}
                        >
                            Log out
                        </Button>
                    </Link>,
                key: 'logout'
            }
        ]
    }
]


const LogOut: React.FC = () => {
    return (
        <Menu
            mode='horizontal'

            items={userItems}
            style={{
                backgroundColor: 'transparent',
            }}
        />
    )
}

export default LogOut
