import {Button, Form, Input, message, Modal, Select, Space} from 'antd'
import React, {useState} from 'react'
import {listToKeyValue} from "../utils/utils"
import {DeleteOutlined, EditOutlined} from "@ant-design/icons"
import {fetchBackendToken} from "../utils/backend"


const SelectScenario: React.FC<any> = (
    {
        scenarios,
        setScenariosLoading,
        compact = false
    }) => {
    const ref = scenarios.ref

    async function fetchScenario(scenario_key: string) {
        console.log('fetching scenario', scenario_key)
        await fetchBackendToken(`set_scenario?ref=${ref}&scenario_key=${scenario_key}`)
        setScenariosLoading('init')
    }


    async function addScenario(constrains: any) {
        await fetchBackendToken(`add_scenario?ref=${ref}`,
            {
                method: 'POST',
                body: JSON.stringify(constrains)
            })
        setScenariosLoading('init')
    }

    async function deleteScenario(scenario_key: string) {
        if (scenarios.scenarios_keys.length === 1)
            return message.error('You must have at least one scenario')
        if (scenario_key === 'scenario 1')
            return message.error('You cannot delete the default scenario')
        setScenariosLoading(true)
        await fetchBackendToken(`delete_scenario?ref=${ref}&scenario_key=${scenario_key}`,
            {method: 'DELETE'})
        setScenariosLoading('init')

    }

    async function editScenario(old_scenario_key: string, new_scenario_key: string) {
        if (old_scenario_key === 'scenario 1')
            return message.error('You cannot edit the default scenario')
        setScenariosLoading(true)
        await fetchBackendToken(`edit_scenario?ref=${ref}&old_scenario_key=${old_scenario_key}&new_scenario_key=${new_scenario_key}`,
            {method: 'POST'})
        setScenariosLoading('init')
    }

    const [showModalDeleteScenario, setShowModalDeleteScenario] = useState(false)
    const [showModalEditScenario, setShowModalEditScenario] = useState(false)
    const [formEditScenario] = Form.useForm()
    let style: any = {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    }
    if (compact) {
        style = {
            display: 'flex',
            width: '100%',
        }
    }

    return (
        <div style={style}>
            <div
                style={{
                    marginRight: '10px',
                    display: 'flex',
                    // justifyContent: 'center',
                    alignItems: 'center',
                    }}

            >Historial:</div>

            {compact ?
                <Space.Compact
                    style={{width: '100%'}}
                >
                    <Select
                        placeholder='Historial'
                        value={scenarios.scenario_key}
                        options={listToKeyValue(scenarios.scenarios_keys)}
                        onChange={(value) => fetchScenario(value)}
                        style={{width: '80%'}}
                    />

                    <Button
                        icon={<EditOutlined/>}
                        onClick={() => setShowModalEditScenario(true)}
                        // style={{width: '100%'}}
                    />

                    <Button
                        icon={<DeleteOutlined/>}
                        onClick={() => setShowModalDeleteScenario(true)}
                        // style={{width: '100%'}}
                    />
                </Space.Compact>
                :
                <>

                    <Select
                        placeholder='Historial'
                        value={scenarios.scenario_key}
                        options={listToKeyValue(scenarios.scenarios_keys)}
                        onChange={(value) => fetchScenario(value)}
                        style={{width: '100%'}}
                    />

                    <Button
                        icon={<EditOutlined/>} onClick={() => setShowModalEditScenario(true)}
                        style={{width: '100%'}}
                    >
                        Editar nombre
                    </Button>

                    <Button
                        icon={<DeleteOutlined/>} onClick={() => setShowModalDeleteScenario(true)}
                        style={{width: '100%'}}
                    >
                        Borrar
                    </Button>
                </>
            }


            <Modal
                title='Delete scenario?'
                open={showModalDeleteScenario}
                onOk={async () => {
                    await deleteScenario(scenarios.scenario_key)
                    setShowModalDeleteScenario(false)
                }}
                okText='Delete'
                okButtonProps={{danger: true}}
                onCancel={() => setShowModalDeleteScenario(false)}
            >
                Are you sure you want to delete this scenario?
            </Modal>
            <Modal
                title='Rename scenario?'
                open={showModalEditScenario}
                onOk={async () => {
                    await formEditScenario.submit()
                    const tmpScenarioKey = formEditScenario.getFieldValue('inputField')
                    await editScenario(scenarios.scenario_key, tmpScenarioKey)
                    setShowModalEditScenario(false)
                }}
                okText='Rename'
                onCancel={() => setShowModalEditScenario(false)}
            >
                <Form form={formEditScenario}>
                    <Form.Item
                        name='inputField'
                        rules={[
                            {
                                validator: async (_, value) =>
                                    value && value.length > 3 ? await Promise.resolve() : await Promise.reject(new Error('Input must be at least 3 characters long'))
                            }
                        ]}
                    >
                        <Input placeholder='scenario name'/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>

    )
}

export default SelectScenario
