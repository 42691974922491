export const initSessionState = {
    ref: 'init',
    scenario_key: null,
    scenarios_keys: [],
}

export const initScenario = {
    ref: 'init'
}



