import React from 'react'
import {Alert, Layout, Typography} from 'antd'
import {Login_} from './Login'
import {useLocation} from 'react-router-dom'
import {ReactComponent as Logo} from '../icons/base_logo.svg'

const {Title} = Typography

const {Content, Footer} = Layout

const Signup: React.FC = () => {
    const location = useLocation()
    const email = location?.state?.email
    return (
        <Content
            className='content'
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: ' 5vh'
                // height: '80vh',
            }}
        >
            <Logo
                style={{
                    width: '20%',
                    height: '20%',
                    margin: '0%',
                    padding: '0%'
                }}
            />
            <Title level={2}>Sign up</Title>
            <Login_/>
            <p>
                <Alert
                    description={
                        <div style={{textAlign: 'center'}}>
                            Your mail {email} is not whitelisted.<br/>
                            Please contact xavier@boschmonart.com
                        </div>
                    }
                    type='warning' showIcon
                />
            </p>
        </Content>
    )
}

export default Signup
