import React from 'react';
import {ReactComponent as GoogleLogo} from './google_logo.svg';

interface GoogleIconProps {
    style?: React.CSSProperties;
    className?: string;
}

const GoogleIcon: React.FC<GoogleIconProps> = ({style, className}) => {
    return (
        <div style={style}>
            <GoogleLogo
                className={className}
                width="1em"
                height="1em"
            />
        </div>
    );
};

export default GoogleIcon;