import React from 'react';
import {Button, Layout, Typography} from 'antd';
import {useSessionState} from "../utils/session_state"
import SelectScenario from '../components/SelectScenario';


const {Content, Footer} = Layout;

const Dev = () => {
    const {sessionState, setSessionState, setSessionStateLoading} = useSessionState()
    const a = ''
    let b = 'b'
    console.log('test', a, b, a === b, a == b)
    return (
        <Content className="content"
                 style={{
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                     paddingTop: ' 10vh',
                     height: '60vh'
                 }}>
            <Button type="primary"

                    style={{
                        width: '150px',
                        height: '75px',
                    }}
                    onClick={() => {
                        setSessionState(
                            {...sessionState, scenario_key: null}
                        )
                    }}
            >

                test
            </Button>

        </Content>
    );
};

export default Dev;