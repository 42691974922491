export function listToKeyValue(list: string[]) {
    return list.map((item: string) => {
        return {value: item, label: item}
    })
}

export function getTableColumns(data: any[]) {
    const dataSource = data.map((row: any) => {
        return row
    })
    const data_keys = Object.keys(dataSource[0])

    const columns = data_keys.map(
        (key: string) => {
            return {
                title: key,
                dataIndex: key,
                key
            }
        })
    return columns
}
