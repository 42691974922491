import React, {useEffect, useState} from 'react'
import {GoogleAuthProvider, OAuthProvider, onAuthStateChanged, signInWithPopup, signOut} from 'firebase/auth'

import {Button, Layout, message, Typography} from 'antd'
import GoogleIcon from '../icons/GoogleIcon'
import {useNavigate} from 'react-router-dom'
import {fetchBackend} from '../utils/backend'
import {ReactComponent as Logo} from '../icons/base_logo.svg'
import {auth} from '../utils/firebase'
import MicrosoftIcon from '../icons/MicrosoftIcon'

const {Title} = Typography

const {Content, Footer} = Layout

const Login_: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const providerGoogle = new GoogleAuthProvider()
    const providerMicrosoft = new OAuthProvider('microsoft.com')
    const navigate = useNavigate()

    useEffect(() => {
        signOut(auth)
    }, [])

    async function loginWith(provider: string) {
        const provider_ = provider === 'Google'
            ? providerGoogle
            : provider === 'Microsoft' ? providerMicrosoft : providerGoogle

        setLoading(true)
        await signInWithPopup(auth, provider_)

        onAuthStateChanged(auth, async (user) => {
            if (user == null) {
                const error = 'Failed to log in'
                message.error(error)
                console.error(error)
            } else {
                const token = await user.getIdToken()
                const init = {
                    headers: {
                        token
                    }
                }
                const verify_user = await fetchBackend(
                    'verify_user', init)
                if (verify_user == true) {
                    message.success('Logged in successfully')
                    navigate('/')
                } else {
                    const email = user.email
                    signOut(auth)
                    navigate('/signup', {state: {email}})
                }
                setLoading(false)
            }
        })
    }

    const style =
        {
            fontSize: '15px',
            width: '300px',
            height: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px',
            padding: '20px'
        }

    return (
        <>
            <Button
                type='default' onClick={async () => await loginWith('Google')} loading={loading}
                size='large'
                style={style}
                icon={
                    <GoogleIcon
                        style={{
                            fontSize: '25px'
                        }}
                    />
                }
            >

                Continue with Google
            </Button>


        </>
    )
}

const Login: React.FC = () => {
    return (
        <Content
            className='content'
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: ' 10vh'
                // height: '80vh',
            }}
        >
            <Logo
                style={{
                    width: '20%',
                    height: '20%',
                    margin: '0%',
                    padding: '0%'
                }}
            />
            <Title level={2}>Log in</Title>
            <Login_/>

        </Content>
    )
}

export {Login_}
export default Login
